import { computed, onMounted, onUnmounted, ref, watch } from "vue";

export function useClickOutside(targetRef, callback) {
    const handleClick = (event) => {
        if (targetRef.value && !targetRef.value.contains(event.target)) {
            callback(event);
        }
    };

    onMounted(() => {
        document.addEventListener("click", handleClick);
    });

    onUnmounted(() => {
        document.removeEventListener("click", handleClick);
    });
}

const defaultWindow = typeof window !== "undefined" && window;

const windowSize = ref({ width: 0, height: 0 });

const setSize = () => {
    windowSize.value = {
        width: defaultWindow.innerWidth,
        height: defaultWindow.innerHeight,
    };
};

const DESKTOP_BREAKPOINT = 1280;
const MOBILE_BREAKPOINT = 480;

let listenersCount = 0;

export function useWindowSize() {
    setSize();

    onMounted(() => {
        if (listenersCount === 0) {
            defaultWindow.addEventListener("resize", setSize);
        }
        listenersCount++;
    });

    onUnmounted(() => {
        listenersCount--;
        if (listenersCount === 0) {
            defaultWindow.removeEventListener("resize", setSize);
        }
    });

    const isMobile = computed(
        () => windowSize.value.width < DESKTOP_BREAKPOINT,
    );
    const isTablet = computed(
        () =>
            windowSize.value.width > MOBILE_BREAKPOINT &&
            windowSize.value.width < DESKTOP_BREAKPOINT,
    );
    const isXs = computed(() => windowSize.value.width < MOBILE_BREAKPOINT);
    const isDesktop = computed(
        () => windowSize.value.width >= DESKTOP_BREAKPOINT,
    );

    const width = computed(() => windowSize.value.width);
    const height = computed(() => windowSize.value.height);

    return {
        isMobile,
        isTablet,
        isXs,
        isDesktop,
        width,
        height,
    };
}

export function useOverflowHandler(contentRef, isOpen) {
    const checkOverflow = () => {
        const content = contentRef.value;
        if (!content || typeof window === "undefined") return;
        content.style.top = "";
        content.style.bottom = "";
        content.style.left = "";
        content.style.right = "";

        const rect = content.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        if (rect.bottom > viewportHeight) {
            const difference = rect.bottom - viewportHeight;
            content.style.top = `${content.offsetTop - difference - 10}px`;
        }

        if (rect.right > viewportWidth) {
            const difference = rect.right - viewportWidth;
            content.style.left = `${content.offsetLeft - difference - 10}px`;
        }

        if (rect.top < 0) {
            const difference = -rect.top;
            content.style.top = `${content.offsetTop + difference + 10}px`;
        }

        if (rect.left < 0) {
            const difference = -rect.left;
            content.style.left = `${content.offsetLeft + difference + 10}px`;
        }
    };

    watch(isOpen, (newVal) => {
        if (newVal) {
            setTimeout(checkOverflow, 0);
        }
    });

    onMounted(() => {
        checkOverflow();
    });

    return { checkOverflow };
}

export function useAdaptiveOverflowHandler(
    contentRef,
    isOpen,
    buttonRef = null,
    direction = "bottom",
) {
    let enableSwitching = false;

    if (buttonRef) {
        enableSwitching = true;
    }

    const adjustPosition = () => {
        const content = contentRef.value;
        if (!content || typeof window === "undefined") return;

        content.style.top = "";

        let rect = content.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        if (enableSwitching) {
            const buttonElement = buttonRef.value;
            const buttonRect = buttonElement?.getBoundingClientRect();
            const buttonHeight = buttonRect.height;
            const spaceAbove = buttonRect.y;
            const spaceBelow = viewportHeight - buttonRect.y - buttonHeight;
            const scrollY = window.scrollY || document.documentElement.scrollTop;

            if (direction === "bottom") {
                content.style.top = `${buttonRect.bottom + scrollY + 1}px`;
            }

            if (direction === "top") {
                content.style.bottom = `${buttonRect.top - buttonRect.height + scrollY + 6}px`;
            }

            content.style.left = `${buttonRect.left + window.scrollX}px`;
            content.style.width = `${buttonRect.width}px`;

            rect = content.getBoundingClientRect();
        }

        if (rect.bottom > viewportHeight) {
            const difference = rect.bottom - viewportHeight;
            content.style.top = `${content.offsetTop - difference - 10}px`;
        }

        if (rect.right > viewportWidth) {
            const difference = rect.right - viewportWidth;
            content.style.left = `${content.offsetLeft - difference - 10}px`;
        }

        if (rect.top < 0) {
            const difference = -rect.top;
            content.style.top = `${content.offsetTop + difference + 10}px`;
        }

        if (rect.left < 0) {
            const difference = -rect.left;
            content.style.left = `${content.offsetLeft + difference + 10}px`;
        }
    };

    watch(isOpen, (newVal) => {
        if (newVal) {
            setTimeout(adjustPosition, 0);
        }
    });

    onMounted(() => {
        adjustPosition();
        defaultWindow.addEventListener("resize", adjustPosition);
    });

    onUnmounted(() => {
        defaultWindow.removeEventListener("resize", adjustPosition);
    });

    return { adjustPosition };
}

<template>
    <transition name="popup-transition">
        <slot></slot>
    </transition>
</template>

<style scoped>
    .popup-transition-enter-active,
    .popup-transition-leave-active {
        transition:
            transform 200ms ease-out,
            opacity 200ms ease-out;
    }

    .popup-transition-enter-from,
    .popup-transition-leave-to {
        transform: scale(0.95);
        opacity: 0;
    }

    .popup-transition-enter-to,
    .popup-transition-leave-from {
        transform: scale(1);
        opacity: 1;
    }
</style>
